<template>
  <div class="dashboard-content" data-app>
    <div class="row">
      <!-- Profile -->
      <div class="col-lg-6 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <h4 class="mb-3">{{ $t('Views.AdminProfile.title1') }}</h4>
            <!-- Avatar -->
            <!-- Details -->
            <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-img
                  v-if="user"
                  :src="
                    user.avatar
                      ? user.avatar.route
                      : 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/user-icon-placeholder.png'
                  "
                  :lazy-src="`https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/user-icon-placeholder.png`"
                  :alt="'logo'"
                  aspect-ratio="3"
                  :key="imgKey"
                  contain
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-3" align="center" justify="center">
                      <v-progress-circular
                        class="mx-auto"
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="12" lg="6" md="12" sm="12">
                <v-text-field
                  :rules="rules.name"
                  :label="$t('Views.AdminProfile.labelName')"
                  v-model="name"
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6" md="12" sm="12">
                <v-text-field
                  :rules="rules.lastname"
                  :label="$t('Views.AdminProfile.labelLastname')"
                  v-model="lastname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6" md="12" sm="12">
                <v-text-field
                  :rules="rules.username"
                  :label="$t('Views.AdminProfile.labelUsername')"
                  v-model="username"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6" md="12" sm="12">
                <v-text-field
                  :rules="rules.phone"
                  :label="$t('Views.AdminProfile.labelPhone')"
                  v-model="phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                  :rules="rules.email"
                  :label="$t('Views.AdminProfile.labelEmail')"
                  v-model="email"
                ></v-text-field>
              </v-col>
            </v-form>
            <v-btn
              style="margin: auto; margin-top: 10px"
              :disabled="!valid"
              dark
              color="#0c2c7c"
              @click="updateItem()"
            >
              {{ $t('Views.AdminProfile.saveButton') }}
            </v-btn>
            <br />
            <br />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <h4 class="mb-3">{{ $t('Views.AdminProfile.title2') }}</h4>
            <template>
              <v-form ref="formAvatar" v-model="valid" style="width: 100%" class="row">
                <v-file-input
                  :rules="rules.avatar"
                  accept="image/png, image/jpeg, image/jpg, image/bmp"
                  placeholder="Pick an avatar"
                  prepend-icon="mdi-camera"
                  v-model="avatar"
                  :label="$t('Views.AdminProfile.labelAvatar')"
                ></v-file-input>
              </v-form>
              <v-btn
                style="margin: auto; margin-top: 10px"
                :disabled="!valid"
                dark
                color="#0c2c7c"
                @click="saveAvatar()"
              >
                {{ $t('Views.AdminProfile.saveButton') }}
              </v-btn>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Profile',
    data() {
      return {
        config: {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: this.$session.get('tokenSession')
              ? 'Bearer ' + this.$session.get('tokenSession')
              : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiY2VlN2IwYTA0MjE4MGQ1OGU2YjkyZWJhZGI5YTVjZDY0OGE0ODNlMmU5N2FmZmM2YmU5ODcyMDJlMWExNDMyMmU1OGU0M2RlZDljYjU2NmEiLCJpYXQiOjE2MTgwMjIyODkuNDQ4MDgzLCJuYmYiOjE2MTgwMjIyODkuNDQ4MDg5LCJleHAiOjE2NDk1NTgyODkuMDEwNTcsInN1YiI6IjUiLCJzY29wZXMiOltdfQ.ZN43rQZjIZRcNxR_C0rJZNyRYrwWrsLnFJejA2-2ZBLu8ZlmzlP5_UTELA_VsH33J_3foj2wBlp0sVYAGz1YiswOkLzGDkWp_YLz4RJP5eHDwm1tTydS0XLtiTDkEYBJCuPYPo1pQx70QIz0z5WcFNnoQM_XZKMHHBnYj_wnYCHufanLARFSnaMQxRAt2tdMdSlStmib8y039UMM4yYMJH15NLYQzal_ktYL_mCTH9x0QBEpz2kJphi-sw0WrF6TwZBmgg8WDfIYa-giWh6BReCbUgDSTurqALzEFFkGeTZTpYf56xxvjAoCAZFz0ts2TtVFKjROnyWf2AuRj6cv-63w-WJPoWWSZlKCzVbFbi6mDJ9_IXYW14wOdDP7Qx9pswevrTMmWBl1wk0TiTQeOX7RwsLWIh0ds7YMk8I2fd1Dkv9Hc80ii_GLXM4Yy2pwTXqLDEdX2OX0FtD8BN0c-e3DN4NORif7DFKvCCA8N1wKWCV_i5Uum3nay8rCEN-j1lS7NMzLA4cDTBe2oUUJzL1tHJa7AtuUOiQQUHJ5QeoAkO7m3b1Zp5AcyQtMJnjIERUG_rHCbV8Wmx9hmUv_0pkgzPg8Q2viibSMy3YEvkmzIaROGUVzeIWNnN9N4i6sBxHyNT11gXn3DMv1LShoaMbXvjxbPCda-_eF5iGmBrQ',
          },
        },
        configFile: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        rules: {
          name: [(v) => !!v || 'Este campo es requerido'],
          phone: [(v) => !!v || 'Este campo es requerido'],
          lastname: [(v) => !!v || 'Este campo es requerido'],
          username: [(v) => !!v || 'Este campo es requerido'],
          email: [(v) => !!v || 'Este campo es requerido'],
          avatar: [
            (v) => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
          ],
        },
        valid: true,
        loading: false,
        name: '',
        lastname: '',
        phone: '',
        email: '',
        avatar: [],
        user: [],
        username: '',
        imgKey: 1,
      }
    },
    created() {
      this.userData()
    },
    methods: {
      forceRerender() {
        this.imgKey++
      },
      async userData() {
        axios.get('auth/user').then((response) => {
          this.user = response.data
          this.name = this.user.name
          this.lastname = this.user.lastname
          this.email = this.user.email
          this.phone = this.user.phone
          this.username = this.user.username
          if (this.user.avatar) {
            this.$session.set('userAvatar', this.user.avatar)
            this.forceRerender()
          }
          this.$emit('changeAvatar')
        })
      },
      async updateItem() {
        let dataPost = {
          name: this.name,
          lastname: this.lastname,
          phone: this.phone,
          email: this.email,
          username: this.username,
        }
        this.loading = true
        axios
          .put('users/' + this.user.id, dataPost)
          .then((response) => {
            this.userData()
            this.$swal({
              title: `¡Información actualizada!`,
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Aceptar',
            })
          })
          .finally(() => (this.loading = false))
      },
      async saveAvatar() {
        var form = new FormData()
        form.append('file', this.avatar)
        form.append('fileType', 'avatar')
        form.append('_method', 'patch')
        form.append('fileResize[width]', 100)
        form.append('fileResize[height]', 100)
        if (this.user.avatar) {
          form.append('fileId', this.user.avatar.id)
        }
        axios.post('users/' + this.user.id, form, this.configFile).then(() => {
          location.reload()
        })
      },
    },
  }
</script>
